import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("往来单位编号"),
    dataIndex: "number",
    key: "number",
    sorter: true,
  },
  {
    title: T("往来单位名称"),
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: T("往来单位类型"),
    dataIndex: "type_display",
  },
  {
    title: T("联系人"),
    dataIndex: "contacts",
    key: "contacts",
  },
  {
    title: T("电话"),
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    key: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
